/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { useLazyQuery } from 'react-apollo'
import gql from 'graphql-tag'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

let baseUrl = 'application.cogniable.us'
if (REACT_APP_API_URL.includes('development')) baseUrl = 'development.cogniable.us'
if (REACT_APP_API_URL.includes('quality')) baseUrl = 'quality.cogniable.us'

export const GET_POSITION_MENU = gql`
  query staffPositionMenu($positionId: ID!) {
    staffPositionMenu(staffPositionId: $positionId) {
      staffPosition {
        id
        title
      }
      clinic {
        schoolName
      }
      filteredKeys
    }
  }
`

const MenuLeft = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const menu = useSelector(state => state.menu)
  const settings = useSelector(state => state.settings)
  const school = useSelector(state => state.school)
  const { mlGraph, mlRecommendation, mlSimilarity } = school
  const { isMenuCollapsed, isMobileView, isSettingsOpen, isLightTheme, isMobileMenuOpen } = settings
  const { menuTopData } = menu
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])
  const [staffMenuList, setStaffMenuList] = useState([])

  const [getPositionMenu, { data, loading, error }] = useLazyQuery()

  useEffect(() => {
    if (
      user &&
      user.staffObject &&
      user.staffObject?.position !== null &&
      user.staffObject?.position !== undefined
    ) {
      getPositionMenu({
        variables: {
          positionId: user.staffObject?.position?.id,
        },
      })
    }
  }, [user])

  useEffect(() => {
    if (data && data.staffPositionMenu.length) {
      setStaffMenuList(data.staffPositionMenu[0]?.filteredKeys)
    }
  }, [data])

  useEffect(() => {
    setKeys(props)
  }, [props])

  useEffect(() => {
    if (isMenuCollapsed && !isMobileView) {
      setOpenedKeys([])
    }
  }, [isMenuCollapsed, isMobileView])

  const setKeys = prop => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuTopData, 'children'), [
      'url',
      prop.location.pathname,
    ])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    setOpenedKeys([])
  }

  const onOpenChange = openeKeys => {
    store.set('app.menu.openedKeys', openeKeys)
    setOpenedKeys(openeKeys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    setSelectedKeys([e.key])
  }

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const generateMenuItems = () => {
    const generateItem = (item, isSubMenu) => {
      const { key, title, url, icon, disabled, pro } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item
            key={key}
            disabled={
              staffMenuList.includes(key) || key === 'MLRecommendationModel'
                ? !mlRecommendation
                : // eslint-disable-next-line no-nested-ternary
                key === 'MLGraphModel'
                ? !mlGraph
                : key === 'MLAssessments'
                ? !mlSimilarity
                : false
            }
          >
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && (
                  <>
                    <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />
                    <span className={styles.title}>{title}</span>
                  </>
                )}
                {isSubMenu && (
                  <>
                    <span className={styles.title}>
                      {/* <Trans>{title}</Trans> */}
                      {title}
                    </span>
                  </>
                )}
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
          {pro && <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuTopData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: 256,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: 256,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  const menuData = generateMenuItems()

  return (
    <Sider
      {...menuSettings}
      className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
    >
      <div className={styles.logo}>
        <div className={styles.logoContainer}>
          <img
            src="resources/images/HeaderLogo.png"
            alt="CogniAble Logo"
            style={{ marginLeft: '14px' }}
          />
        </div>
      </div>
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...pro }) => (
          <div
            {...pro}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <div
          style={{
            justifyContent: 'center',
            marginLeft: '10%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <strong>
            Hello ,&nbsp;
            {user.role === 'parents'
              ? user.parentName
              : user.role === 'therapist'
              ? user.staffName
              : user.role === 'school_admin'
              ? user.clinicName
              : 'Anonymous'}
          </strong>
          <div>
            <strong>Role: </strong>
            {user.role === 'parents' ? 'Parent' : ''}
            {user.role === 'therapist' ? 'Therapist' : ''}
            {user.role === 'school_admin' ? 'Clinic' : ''}
          </div>
        </div>

        <Menu
          theme={isLightTheme ? 'light' : 'dark'}
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          className={styles.navigation}
        >
          <Menu.Divider />
          {menuData}
          <Menu.Item key={Math.random()} disabled={false}>
            <a href="/#/clinicSupportTicket" rel="noopener noreferrer">
              <span className={`icmn-ticket ${styles.icon} icon-collapsed-hidden`} />
              <span className={styles.title}>Tickets</span>
            </a>
          </Menu.Item>
          <Menu.Item key={Math.random()} disabled={false} onClick={logout}>
            <span className={`icmn-exit ${styles.icon} icon-collapsed-hidden`} />
            <span className={styles.title}>Log Out</span>
          </Menu.Item>
        </Menu>
      </Scrollbars>
    </Sider>
  )
}

export default withRouter(MenuLeft)
