/* eslint-disable no-unused-vars */
import { GraphQLClient } from 'graphql-request'
import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import { gql } from 'apollo-boost'
import client from '../config'
import apolloClient from '../apollo/config'

const firebaseConfig = {
  apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
  authDomain: 'cleanui-72a42.firebaseapp.com',
  databaseURL: 'https://cleanui-72a42.firebaseio.com',
  projectId: 'cleanui-72a42',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '583382839121',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth
export default firebaseApp

export async function login(payload) {
  const query = `mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(input: {username: $username, password: $password}) {
      token,
      user{
        id,
        firstName
        groups{
          edges {
            node {
              id,
              name
            }
          }
        }
        studentsSet{
          edges {
            node {
              id
              email
              parentName
              parent {
                id
              }
              language{
                id 
                name
              }

            }
          }
        }
        staffSet{
          edges {
            node {
              id
              name
              surname
              email
              state
              country
              designation
              school {
                id
                schoolName
                user{
                  id
                }
              }
              credentials {
                id
                name
                goals
                shortGoals
                targetAllocation
              }
            }
          }
        }
      }
    }
  }`
  const variables = {
    username: payload.username,
    password: payload.password,
  }

  return apolloClient
    .mutate({
      mutation: gql`
        mutation TokenAuth($username: String!, $password: String!) {
          tokenAuth(input: { username: $username, password: $password }) {
            token
            # firstLogin
            user {
              id
              firstName
              lastName
              email
              username
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              studentsSet {
                edges {
                  node {
                    id
                    parentName
                    parent {
                      id
                    }
                    language {
                      id
                      name
                    }
                  }
                }
              }
              staffSet {
                edges {
                  node {
                    id
                    name
                    surname
                    email
                    state
                    image
                    country
                    designation
                    position {
                      id
                      title
                    }
                    school {
                      id
                      schoolName
                      user {
                        id
                      }
                    }
                    credentials {
                      id
                      name
                      goals
                      shortGoal
                      targetAllocation
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        username: payload.username.trim(),
        password: payload.password,
      },
    })
    .then(data => {
      console.log(data, 'user data')
      localStorage.setItem('token', JSON.stringify(data?.data.tokenAuth.token))
      if (data?.data.tokenAuth.user) {
        localStorage.setItem('userName', JSON.stringify(data?.data.tokenAuth.user.firstName))
      }
      if (data?.data.tokenAuth.user.groups.edges.length > 0) {
        localStorage.setItem(
          'role',
          JSON.stringify(data?.data.tokenAuth.user.groups.edges[0]?.node.name),
        )
      }
      localStorage.setItem('database', JSON.stringify('india'))
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function StudentIdFromUserId(payload) {
  return apolloClient
    .query({
      query: gql`{
          students (parent:"${payload}")  {
            edges {
              node {
                id, parentName,
              }
            }
          }
        }`,
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}

export async function clinicDetails() {
  return apolloClient
    .query({
      query: gql`
        {
          schoolDetail {
            id
            schoolName
            address
            email
            mlSimilarity
            mlGraph
            mlRecommendation
            country {
              id
              name
            }
          }
        }
      `,
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function StaffIdFromUserId(payload) {
  return apolloClient
    .query({
      query: gql`{
          staffs (user:"${payload}")  {
            edges {
              node {
                id
                name
                surname
                email
                state
                country
                designation
                position {
                  id
                  title
                }
                school {
                  id
                  schoolName
                  user{
                    id
                  }
                }
                credentials {
                  id
                  name
                  goals
                  shortGoal
                  targetAllocation
                  curriculum
                }
              }
            }
          }
        }`,
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}

export async function GetUserDetailsByUsername(payload) {
  return apolloClient
    .query({
      query: gql`{
        getuser(username:"${payload}") {
          edges {
            node {
              id, 
              username,              
        firstName
        lastName
        email
              groups {
                edges {
                  node {
                    id, name
                  }
                }
              }
            }
          }
        }
      }`,
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}

export async function RefreshToken() {
  if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    return false
  }

  const query = `mutation RefreshToken($token: String!) {
        refreshToken(input: {token: $token}) {
          token
          payload
        }
      }`
  const variables = {
    token: JSON.parse(localStorage.getItem('token')),
  }

  return apolloClient
    .mutate({
      mutation: gql`
        mutation RefreshToken($token: String!) {
          refreshToken(input: { token: $token }) {
            token
            payload
          }
        }
      `,
      variables: {
        token: JSON.parse(localStorage.getItem('token')),
      },
    })
    .then(data => {
      localStorage.setItem('database', JSON.stringify('india'))
      localStorage.setItem('token', JSON.stringify(data?.data.refreshToken.token))
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function logout() {
  return apolloClient.cache.reset()
}

export async function GetStudentNameById(payload) {
  return apolloClient
    .query({
      query: gql`{
        student(id: ${payload}) {
          firstname
        }
      }`,
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}
